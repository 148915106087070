import { MaintenanceBundleType } from 'models/enums';

export const POST_INSPECTION_FLOW_STRINGS = {
  'placemat.title.no_steps_completed': `Hi there, {homeownerName}! I am excited to see we're making progress! I wanted to take a moment to introduce you to the rest of my team and next\u00a0steps.`,
  'placemat.title.no_steps_completed_move_out': `Hi there, {homeownerName}! We have an all-star team that is here to support you at any time. Let's\u00a0get\u00a0started!`,
  'placemat.title.at_least_one_step_completed': `Keep going, {homeownerName}! You're doing\u00a0great.`,
  'placemat.title.onl_improvement_steps_completed': `Alright, your home's going to look amazing! Now let's create a plan to get\u00a0it\u00a0loved.`,
  'placemat.improvements.title': 'Your home, up to Belong\u00a0Standards.',
  'placemat.pricing.title': 'Pricing. The key to lower\u00a0vacancy.',
  'placemat.team.title_multi_unit': 'Meet Your Team',
  'placemat.team.title': 'Meet Your Team For',

  // Bundle names
  [MaintenanceBundleType.CodeCompliance]: `Code {br}Compliance`,
  [MaintenanceBundleType.SafetyAndSecurity]: `Safety & {br}Security`,
  [MaintenanceBundleType.Habitability]: `Habitability {br}&\u00a0Comfort`,
  [MaintenanceBundleType.VacancyReduction]: `Vacancy {br}Reduction`,
  [MaintenanceBundleType.AppliancesAndEquipment]: `Appliances & {br}Equipment`,

  // Intro step
  'intro.title.onboarding': `Hi {homeownerName}! We got to know your beautiful home during our inspection. I'd love to walk you through what we\u00a0found.`,
  'intro.title.pre_move_out': `We’re looking forward to getting your home re-listed ASAP, {homeownerName}. To begin, let’s walk through the initial\u00a0findings.`,
  'intro.title.move_out': `With your Residents out, we came in to assess any damage your Residents are responsible for, as well as normal wear and tear that needs to be\u00a0addressed.`,
  'intro.next': `Let's get started!`,
  'intro.divider_section.title': `Belong's Rigorous Standards Of\u00a0Home\u00a0Excellence`,
  'intro.divider_section.whats_next': 'What’s Next',
  'intro.divider_section.body': `We're here to make sure your home is in tip-top shape, because homes treated with love have three key advantages that will save you money in the long\u00a0run.`,
  'intro.divider': [
    `Your home will rent faster, to\u00a0great\u00a0Residents.`,
    `These Residents will stay\u00a0longer.`,
    `And they'll treat your home with love in\u00a0return.`,
  ],
  'intro.checkmark_section.title': `To ensure your home was ready to go, we checked for the\u00a0following...`,
  'intro.inspected_by': `Inspected {inspectedOn}\u00a0By`,
  'intro.assessed_by': `Assessed {assessedOn}\u00a0By`,
  'intro.greenBox':
    'We need to move forward if this improvement report is not approved by {date}. This allows us to return the remaining resident’s security deposit in time. If we don’t, they are eligible to receive the full security\u00a0deposit.',

  'intro.next-steps.first.title': `Pre Move Out`,
  'intro.next-steps.second.title': `Post Move Out`,
  'intro.next-steps.common.title': `Inspection & Improvement Report`,
  'intro.next-steps.first.content': `This includes items residents are responsible for, and maintenance items that will increase your home’s appeal to potential residents, making it quicker to get your home\u00a0loved.`,
  'intro.next-steps.second.content': `This includes items residents are responsible for, and maintenance items that will increase your home’s appeal to potential residents, making it quicker to get your home\u00a0loved.`,

  // Features step
  'features.title': `First, let’s verify the features we found in your home to ensure an accurate\u00a0listing.`,
  'features.divider.features_and_appliances': `Features & {br}Appliances`,
  'features.divider.unique_qualities': `Unique {br}Qualities`,
  'features.divider.unique_quirks': `Unique {br}Quirks`,
  'features.next': `Let’s proceed!`,
  'features.next_something_wrong': `Next`,
  'features.something_off': `Modify`,
  'features.tooltip.qualities': `Your home's qualitative selling points! You may not be able to see these in the photos, but you can feel them, and we'll make sure potential Residents know about them\u00a0too.`,
  'features.tooltip.quirks': `Every home has them. They take some getting used to, but our goal will be to find Residents that learn to love\u00a0them.`,

  'features.labeled_list.qualities.title': 'Unique Qualities',
  'features.labeled_list.qualities.label_text': 'Good',
  'features.labeled_list.quirks.title': 'Quirks',
  'features.labeled_list.quirks.label_text': 'Not great',
  'features.form_section.title': 'Anything we missed or should modify?',
  'features.form_section.placeholder': 'Missing features or corrections',

  // Turnovers Responsibility step
  'responsibility.title.pre_move_out': `At Belong, we believe in making sure your home is safe and secure and in condition to maximize your\u00a0earnings.`,
  'responsibility.title.move_out': `During our 200-point inspection, we found a few items to address. Don’t worry, we'll deduct anything residents are responsible for from the security\u00a0deposit.`,
  'responsibility.title.adopted_home': `During our 200-point inspection, we found a few items to address. Don’t worry, we'll deduct anything residents are responsible for from the security\u00a0deposit.`,
  'responsibility.title.move_out.deposit_free': `During our 200-point inspection, we found a few items to address. Don’t worry, we'll deduct anything residents are responsible for from the security\u00a0deposit.`,
  'responsibility.our_promise.title': `Our Promise To You`,
  'responsibility.next': 'Next',
  'responsibility.belong_standards.title': `Belong’s Standards`,
  'responsibility.wear_and_tear.title': 'Common Repairs In The Home',
  'responsibility.wear_and_tear_vs_resident_damage.title': `What is wear and tear vs. resident\u00a0damage?`,
  'responsibility.wear_and_tear_vs_resident_damage.resident.title': 'Damage is always on\u00a0residents.',
  'responsibility.wear_and_tear_vs_resident_damage.resident.copy':
    'Resident’s are responsible for any damage beyond that caused by normal life in the\u00a0home.',
  'responsibility.wear_and_tear_vs_resident_damage.homeowner.title': 'Homeowner Responsibilities',
  'responsibility.wear_and_tear_vs_resident_damage.homeowner.copy':
    'Homeowners are responsible for normal wear and tear and general maintenance of the\u00a0home. ',
  'responsibility.wear_and_tear.body': `We know it can be difficult to differentiate between “wear and tear” and excessive damages. To help, we’ve created side-by-side comparisons of the most common\u00a0repairs.`,
  'responsibility.deposit_deduction.title': 'What are common security deposit deductions?',
  'responsibility.deposit_deduction.items': [
    'Improper Care Of Home',
    'Changing Batteries',
    'Improper Use Of Fixtures',
    'Pet Damage',
    'Painting Due To Damage',
    'Lost Keys',
    'Lost Remotes',
    'Lawn Care',
    'Light Bulbs',
    'Pool Care',
    'Changing Air Filters',
    'Toilet And Drain Clogs',
  ],

  // Improvements step (Review component)
  'improvements.title': `We then took {inspectorName}'s findings and broke them down into 5 categories. Here's how your\u00a0home\u00a0did.`,
  'improvements.title_no_improvements': `Let's not bury the lead. Your home is in great shape and ready to be loved. Kudos to you for keeping it\u00a0impeccable!`,
  'improvements.title_move_out': `Without further ado, here are some of the elements that will help get your home loved again as quickly as\u00a0possible.`,
  'improvements.title_no_improvements_move_out': `Let's not bury the lead. Your home is in great shape and ready to be loved. Kudos to {youAndResidents} for keeping it\u00a0impeccable!`,
  'improvements.flag.text': `Let's discuss!`,
  'improvements.by-you': 'Requested By You',
  'improvements.complimentary': 'Complimentary',
  'improvements.on-you': 'On You',
  'improvements.resident_responsibility': 'Resident Responsibility',
  'improvements.shared_responsibility': 'Shared Responsibility',
  'improvements.resident_responsibility.tooltip':
    'Due to excessive damages beyond normal wear and tear, your resident is fully responsible for the cost of this\u00a0repair.',
  'improvements.shared_responsibility.tooltip':
    "Based on our in-house expert's findings, your {party} is partially responsible for the cost of this\u00a0repair.",
  'improvements.required': `Belong requires any improvements needed to satisfy regulatory building codes. On top of this, we have developed a set of minimum standards designed to ensure Residents living in Belong homes feel cared for, so they in turn feel a sense of\u00a0responsibility.`,
  'improvements.recommended': `Belong recommends any improvements that make the home desirable to high-quality Residents and maximize the long-term value of the\u00a0home.`,
  'improvements.flagged': `Ok, I’ll reach out to discuss {thisOrThese} with you. In the meantime, feel free to move forward with the\u00a0rest.`,
  'improvements.optional': 'Optional',
  'improvements.TBD_reason.MultipleQuotesRequired': `In order to get you the best price possible, we'll want to seek multiple quotes for this\u00a0one.`,
  'improvements.TBD_reason.MultipleOptionsToDiscuss': `There are a few different paths we can take here. We'll go through all of this with you, and determine together the best path\u00a0forward.`,
  'improvements.TBD_reason.PendingFurtherInvestigation': `We weren't able to determine the issue onsite during the inspection. Looks like we'll need a specialist for this one to investigate further and determine the root\u00a0cause.`,

  // Quality step
  'quality.subtitle': 'We treat every home project as if it was our\u00a0own.',
  'quality.markup': 'No Mark-Ups, Ever!',
  'quality.markupDescription':
    'We make no margin on repairs in your home. Our goal is to maximize rental value and minimize in-lease\u00a0repairs.',
  'quality.penny': 'Not a Penny More',
  'quality.pennyDescription':
    'Never pay more than your approved limit. If our costs exceed your approval, we’ll cover the\u00a0difference.',
  'quality.guarantee': 'Guaranteed Time to List',
  'quality.guaranteeDescription':
    'When Belong handles all repairs, we list your home faster and guarantee your professional listing\u00a0date.',
  'quality.liability': 'Liability Protection',
  'quality.liabilityDescription':
    'Accidents with unlicensed vendors can be costly. Belong’s licensed, bonded, insured vendors shield you from\u00a0risk.',
  'quality.title':
    'During our 200-point inspection, we found a few items to take care of to prevent surprise charges when residents move\u00a0in.',
  'quality.title.noImprovements':
    'During our 200-point inspection, we found your home is in excellent condition! No repairs are needed before residents move\u00a0in.',
  'quality.heading': `Backed By Belong's {br}Delightful\u00a0Guarantee`,
  'quality.headingTooltip': `We created the Belong Delightful Guarantee to ensure that every Belong Home is treated with the same level of care as each of our homes. If you aren’t satisfied with the work that was performed by one of Belong’s trusted Pros, we guarantee we will do the work again until the works is done\u00a0right.`,
  'quality.cta': `View Report`,
  'quality.cta.noImprovements': 'Done',
  'quality.secondary_cta': 'Learn More',
  'quality.pro_hero.pretitle': `That advantage starts with a simple\u00a0idea.`,
  'quality.pro_hero.title': `We treat your home as if it were our\u00a0own.`,
  'quality.checklist.title': `When we send Pros, they all meet 4 key\u00a0standards.`,
  'quality.maintenance_talent.title': `We ensure the best quality of work, all at market\u00a0rates.`,
  'quality.general_contractors.title': `Meet Milan, a member of our team of General\u00a0Contractors.`,
  'quality.general_contractors.subtitle': `He's obsessive about detail-oriented\u00a0craftsmanship. No\u00a0matter\u00a0how\u00a0big the\u00a0job\u00a0is.`,
  'quality.pro_hero2.title': `We'll nail it, whether your home has a leak, or needs a\u00a0new\u00a0bathroom.`,
  'quality.diy_section.title': `Why you can trust\u00a0Belong.`,
  'quality.diy_section.subtitle': `We require all work done on your home be performed by a trusted Pro for 3\u00a0reasons.`,
  'quality.data_speak': 'Let’s have the data speak for\u00a0itself.',
  'quality.horror_section.title': `Not convinced?`,
  'quality.horror_section.subtitle': `Check out some DIY gone\u00a0wrong.`,
  'quality.try_us.title': `Live in the area? Give\u00a0us\u00a0a\u00a0try!`,
  'quality.try_us.subtitle': `Something need fixing in your primary residence? We'd be happy to help with that too! Just let us know, and we'll send a Pro right\u00a0out.`,

  // Approval Step
  'approvals.title': `Let’s get your home ready, shall\u00a0we?`,
  'approvals.title.adpted_lease': `Let’s finalize these repairs to keep your home in top\u00a0condition.`,
  'approvals.title_with_gttl': `Approve these repairs, and we'll have your home professionally listed in {date} or\u00a0less.`,
  'approvals.title_with_gttlease': `Approve these repairs so we can list your home. From there, you’ll have a signed lease in an average of 21 days, and never more than {date}. Guaranteed, or the placement fee is on\u00a0us.`,
  'approvals.title_pre_move_out': `Check out what we found. We'll come back once your resident moves to finalize the list. You don’t owe anything at this\u00a0time.`,
  'approvals.title_move_out': `Let’s finalize these repairs to keep your home in top condition. We will deduct all repairs allowed by law from your Resident’s security\u00a0deposit.`,
  'approvals.cta': `View Payment Options`,
  'approvals.cta_pre_move_out': `Next`,
  'approvals.cta_no_approval': `Done`,
  'approvals.resident_responsibility.title': `Resident Responsibility`,
  'approvals.resident_responsibility.subtext': [
    `Below are the repair costs that your previous residents are responsible for. These charges will be deducted from their security\u00a0deposit.`,
  ],
  'approvals.inspirational.title': `Level Up Your Home (Optional) `,
  'approvals.inspirational.title_move_out': `Make Your Home Shine `,
  'approvals.inspirational.subtext': [
    `Our experienced inspectors did a thorough review of your home. Depending on your budget, you may want to make your home even more desirable. It’s up to\u00a0you!`,
  ],
  'approvals.inspirational.subtext_move_out': [
    `Here are a few items that add value to your home and increase the likelihood of future residents living in your home long\u00a0term.`,
  ],
  'approvals.question.placeholder': `Other Improvements (OPTIONAL)`,
  'approvals.rewards.unlock.cost': `Add {cost} more to unlock.`,
  'approvals.rewards.unlock.all': `Add all packages to unlock.`,
  'approvals.rewards.unlock.amount': 'Save 10% when you invest {amounth}.',
  'approvals.paymentplan.title': `Ready to get your\u00a0home\u00a0loved?`,
  'approvals.paymentplan.flag.subtext': `We'll reach out to discuss any items you flagged. Your plan can still be modified after approving\u00a0here.`,
  'approvals.paymentplan.noflag.subtext': `Your plan can still be modified after\u00a0approving\u00a0here.`,
  'approvals.paymentplan.ach.subtext': `We'll use this account to pay you rent as\u00a0well.`,
  'approvals.importance.bundle.title.matter': 'Why {pronoun} {subject} {verb}',
  'approvals.importance.bundle.title.required': 'Why {pronoun} {subject} {verb}\u00a0Required',
  'approvals.importance.modal': 'Why improve My Home\u00a0Now',
  'approvals.importance.title': 'Don’t fall into the “as\u00a0is”\u00a0trap!',
  'approvals.importance.subtitle':
    'Listing your home "as is" may seem like a good idea to save time. Some garden-variety property managers even encourage it. But brushing repairs under the rug causes major problems down\u00a0the\u00a0road.',
  'approvals.importance.vacancy.title': 'Lower Vacancy Upfront',
  'approvals.importance.vacancy.detail':
    'Without cosmetic issues, photos will stand out online, attracting more people to tour. Once onsite, people will jump to apply when they see the high standard of\u00a0care.',
  'approvals.importance.headaches.title': 'Save Yourself Headaches',
  'approvals.importance.headaches.detail':
    'When residents find things broken on move-in day, it’s a poor experience for them and a headache for you. You may even lose them if things go\u00a0unfixed.',
  'approvals.importance.cta': 'Sounds Good',
  'approvals.preventative.tooltip.title': 'Estimated Costs Prevented',
  'approvals.preventative.tooltip.detail':
    'Based on your selection of preventative work, this is an estimate of how much you will save in the long\u00a0run.',
  'approvals.vacancyReduction.tooltip.title': 'Percent Reduction In Time to Love',
  'approvals.vacancyReduction.tooltip.detail':
    'Historically, homeowners who accepted these improvements got their home loved 21%\u00a0faster.',
  'approvals.summary.modal.title': 'Price Details',
  'approvals.summary.modal.plan': 'With {months}-Month Payment Plan',
  'approvals.summary.unlocked.discount': 'You’ve unlocked it!',
  'approvals.summary.remaining.balance': 'You are {balance} away!',
  'approvals.optional': 'Optional',
  'approvals.summary.cost': 'PRICING DETAILS',
  'approvals.payment.plan': 'Up To 12-Month Financing Available',
  'approvals.est': 'Est.',
  'approvals.est.total': 'Est. Total',
  'approvals.payment.upon.completion': 'Upon Completion',
  'approvals.payment.from.rent': 'From Rent',
  'approvals.projectManagementFee.title': `What's Included`,
  'approvals.projectManagementFee.benefits.title': `Benefits`,

  //Homeoenwer Fixes
  'homeowner-fixes.title': `We can't wait to get your home listed, {firstName}! May we ask when the work you are tackling will be done, so we can get\u00a0started?`,
  'homeowner-fixes.date-placeholder': `Completion Date (MM/DD/YYYY)`,

  // Pricing - Get Started
  'pricing.getStarted.title': `Hi {firstName}! Let's walk through the market, our strategy, and pricing recommendation. This will define how we price your home until it’s\u00a0loved.`,
  'pricing.getStarted.title_second': `Hi {firstName}! Let's review Belong's approach to pricing, and recommend a strategy to get your home loved again as soon as\u00a0possible.`,
  'pricing.tip.title': 'We’re 100% aligned with maximizing your\u00a0earnings!',
  'pricing.tip.content':
    'Since we only get paid once your home is loved, we are aligned in minimizing vacancy and maximizing your annual\u00a0income.',
  'pricing.getStarted.cta': `Let's get started!`,
  'pricing.getStarted.pricedOn': `Priced {month} {day} By`,

  // Pricing - Seasonality
  'pricing.seasonality.title': `It is important to understand how seasonality specifically impacts your home’s\u00a0earnings.`,
  'pricing.seasonality.listing_title': `Listing your home at market rate prevents extended\u00a0vacancy.`,
  'pricing.seasonality.listing_subtitle': `We receive 3x more resident leads per home between June and August. We automatically factor this seasonality into the pricing\u00a0strategy.`,
  'pricing.seasonality.impact_title': `Why does this happen?`,
  'pricing.seasonality.impact_content': `For starters, most residents are looking to move before labor day, making spring and summer the high demand\u00a0season.`,
  'pricing.seasonality.cta': `Next`,

  // Pricing - Marketing
  'pricing.marketing.title': `We create the absolute most beautiful listing for your home, and price to ensure as many eyes see it as\u00a0possible.`,
  'pricing.marketing.tabs.highResPhotos': `High-Res Photos`,
  'pricing.marketing.tab-text.highResPhotos': `They say a picture is worth a thousand words. We take high-resolution professional photos that properly show off what is unique about your\u00a0home.`,
  'pricing.marketing.tabs.threeDTours': `3D Tour`,
  'pricing.marketing.tab-text.threeDTours': `With 3D tours, potential residents spend a longer time looking at your listing and getting excited about the space. This increases the chance they take the next step to tour or\u00a0apply. `,
  'pricing.marketing.tabs.virtualTours': `Virtual Tours`,
  'pricing.marketing.tab-text.virtualTours': `Our virtual guided tour brings your home to life and may have you fall in love with your home all over\u00a0again.`,
  'pricing.marketing.tabs.allMajorListings': `All Major Listing Sites`,
  'pricing.marketing.tab-text.allMajorListings': `We have direct integrations with Zillow, Trulia, Zumper and Hotpads that generate 30% - 50% more views on your home on those\u00a0websites.`,
  'pricing.marketing.tabs.weeklyAndSelfTours': `Weekly Tours`,
  'pricing.marketing.tab-text.weeklyAndSelfTours': `We host 2+ group tours and many private tours every week. We’re also one of the only companies with identity verified self tours. Homes with self tours get more demand because potential residents can tour when it’s convenient for\u00a0them.`,
  'pricing.marketing.tip.title': 'Pro Tip',
  'pricing.marketing.tip.content-paragraph1':
    'Homes listed for 20+ days are most likely overpriced. Additionally, because many residents negotiate their rental price, the actual lease contract price is usually 10% less than the last published\u00a0price.',
  'pricing.marketing.cta': 'Next',
  'pricing.marketing.highRes':
    'They say a picture is worth a thousand words. We say a photo gallery is not worthy without drone photos and high resolution photos that properly show off what is unique about your\u00a0home.',
  'pricing.marketing.3dTour':
    'With 3D tours, potential residents spend a longer time looking at your listing and getting excited about the space. This increases the chance they take the next step to tour or\u00a0apply.',
  'pricing.marketing.allListings':
    'We have direct integrations with Zillow, Trulia, Zumper and Hotpads that generate 30%\u00a0- 50% more views on your home on those\u00a0websites.',

  // Pricing - maps
  'map.title': `Supply and demand dictates the price of your home, so let's check out the competition in your\u00a0neighborhood.`,
  'map.not_listed_1': `We can’t wait to get`,
  'map.not_listed_2': 'your home listed and loved\u00a0{heart}',

  'map.card.last_published': 'Last Published Listing\u00a0Price',
  'map.card.tooltip_title_1': 'Last Published Listing Price',
  'map.card.tooltip_subtitle_1': 'Helpful, but often inflated.',
  'map.card.tooltip_body_rental_1':
    'Keep in mind that rent is often negotiated down from the listing price, and the actual price is not\u00a0published.',
  'map.card.tooltip_body_rental_2':
    'Keep in mind Recently Rented Homes are negotiated down and final rental price is not published inflating this\u00a0number.',

  'map.card.days_listed': 'Days Listed',
  'map.card.tooltip_title_2': 'Days Listed',
  'map.card.tooltip_subtitle_2': 'A great indicator of the\u00a0market.',
  'map.card.tooltip_body_2': 'The longer a home is listed, the more likely it is that that home is\u00a0mispriced.',

  'map.button': 'Next',

  // pricing - strategy

  'strategy.title': `As residents view, visit and fall in love with your home, we will use resident interest to determine price\u00a0adjustments.`,
  'strategy.button': 'Next',
  'strategy.slider_left': '0 people',
  'strategy.slider_right': '20+ people',

  // pricing - checkin
  'initial_listing.title':
    'Our goal is to list as closely to the market rate and adjust as quickly as possible. Our data suggests you will maximize your earnings by starting\u00a0here.',
  'initial_listing.relationship': 'How do you feel about your initial listing\u00a0price?',
  'initial_listing.estimated_rental_price': 'Estimated Rental Price',
  'initial_listing.estimated_rental_price_subtitle': 'We think your home will end up renting\u00a0here',
  'initial_listing.initial_listing_tooltip':
    'Tell us what your home should priced at. We don’t recommend listing homes above 5% of our initial\u00a0suggestion.',
  'initial_listing.initial_listing_price': 'Initial Listing Price',
  'initial_listing.initial_listing_price_sub': 'We think your home will rent at this\u00a0price.',
  'initial_listing.modal.title': 'Thank you for sharing your\u00a0thoughts!',
  'initial_listing.modal.subtitle': 'We will review and discuss with you before listing your\u00a0home.',
  'initial_listing.modal.button': 'Next',

  'initial_listing.tell-us-how-you-feel': `What's on your\u00a0mind?`,
  'initial_listing.initial_listing_price_subtitle':
    "Let's start ~{percentageAbove}% higher to\u00a0test\u00a0the\u00a0market",
  'initial_listing.initial_listing_price_subtitle.no_percentage': "Let's start the listing at this\u00a0price.",
  'initial_listing.approve': 'Approve',
  'initial_listing.next': 'Next',
  'initial_listing.discuss': 'Let’s Discuss',

  'initial_listing.why-question': 'Please tell us why you chose this\u00a0price.',
  'initial_listing.why-title': 'Our Thoughts On Starting At A Higher\u00a0Price.',
  'initial_listing.why-description':
    'This price is higher than our initial estimate. High initial listing prices leads to stale listings, and minimal leads. In order to get your home loved, our algorithm adjusts price to avoid a stale listing if demand is\u00a0low.',
  'initial_listing.employee_reminder': `We hear you. Keep in mind, once we list, we can always adjust price up if interest is\u00a0strong.`,

  'initial_listing.alt_tag': 'Home Image',

  // pricing - the market
  'the_market.title': 'To maximize your annual earnings, we must balance rent price and\u00a0vacancy.',
  'the_market.grap.title': 'A higher listing price doesn’t always generate more\u00a0earnings.',
  'the_market.grap.subtitle':
    'Over pricing creates long periods of vacancy. And every day without rent collection adds up to an overall loss in annual\u00a0earnings.',
  'the_market.section.other_factors': 'Curious as to what other factors impact potential\u00a0earnings?',
  'the_market.button_text': 'Next',

  // Pricing - Check In Price
  'pricing.check-in.title.initial-approved': `Prices adjust automatically based on interest. Let’s align on a Floor Price so we can move quickly on your\u00a0behalf.`,
  'pricing.check-in.title.initial-not-approved': `Once we finalize a listing price with you, we make adjustments based on resident interest and are 100% aligned in getting you the most for your home\u00a0ASAP.`,
  'pricing.check-in.critical.title': `Why? Because the first weeks on market are the most\u00a0critical.`,
  'pricing.check-in.critical.content': `Zillow and other major listing sites move your home lower on their search results the longer it is live. It is critical to list at a competitive price and lower price quickly if we don’t see immediate\u00a0interest.`,
  'pricing.check-in.table.title': `Based on our experience with thousands of homes, we built these guidelines for adjusting\u00a0pricing. `,
  'pricing.check-in.automatic-pricing.section-title': `Setting a Floor Price gives us the ability to adjust prices automatically and quickly, minimizing\u00a0vacancy. `,
  'pricing.check-in.automatic-pricing.title': `<b>Prices adjust automatically</b> based on demand, starting after the first 4\u00a0days.`,
  'pricing.check-in.check-points.title': `We'll reach out to you if demand suggests the price should drop below this\u00a0price.`,
  'pricing.check-in.check-points.title-with-diff': `We'll reach out to you if demand suggests the price should drop below this Floor Price, {priceDiff} below your initial listing\u00a0price.`,
  'pricing.check-in.weekly-updates.title': `You’ll recieve frequent <b>email updates</b> about demand and price\u00a0changes.`,
  'pricing.check-in.cta.initial-approved': `Let's list!`,
  'pricing.check-in.cta.initial-not-approved': `Makes sense!`,
  'pricing.check-in.cta.initial-not-happy': `Let's discuss!`,
  'pricing.check-in.how-do-you-feel': `How do you feel about our\u00a0strategy?`,
  'pricing.check-in.our-strategy': `Our strategy successfully maximized earnings by balancing vacancy and rent price for thousands of homeowners. Tell me what's on your\u00a0mind.`,
  'pricing.check-in.tell-us-how-you-feel': `What's on your mind?`,
  'pricing.check-in.cta.with-feedback': `Submit`,
  'pricing.check-in.toast': `Success! You confirmed a Floor Price of {checkInPrice}. We will get busy preparing to list.`,
  'pricing.check-in.toast-bad-sentiment':
    "We'll make sure we can discuss our pricing strategy once more before we\u00a0list.",

  // Success
  'success.title.approved': `And we’re off! We have everything we need from you right now to hit the ground running. Rest assured, you're in good hands, and your home will be receiving all the love it\u00a0deserves.`,
  'success.title.not-approved': `We have your feedback and will follow up with you soon! Rest assured, you're in good hands and we will make sure your home receives all the love it\u00a0deserves.`,
  'success.whats-next': `What's next?`,
  'success.steps.setup-account': `Setup Your Account`,
  'success.steps.your-plan': `Finalize Your Plan`,
  'success.steps.improvements': `Improvements & Reconditioning`,
  'success.steps.photos-media': `Photos & Media`,
  'success.steps.listing-showing': `Listing & Showings`,
  'success.cta': `View Your Account`,

  // Smooth Payments
  'smooth-payments.full-amount.title':
    'Alright! You’ve unlocked Smooth Payments™! We’ll send you ${rent} on the 1st, in anticipation of finding residents at that rental\u00a0price.',
  'smooth-payments.prorated.title':
    "Alright! You’ve unlocked Smooth Rent Payments! We're sending you the remainder of the month (${currentRent}) now, in anticipation of finding residents at that ${nextRent} rental\u00a0price.",
  'smooth-payments.enabled': 'SMOOTH PAYMENTS™ ENABLED',
  'smooth-payments.free-text': 'FREE',
  'smooth-payments.submit.enabled': 'Sounds Great!',
  'smooth-payments.submit.disabled': 'No, thanks!',
  'smooth-payments.modal-title': 'Enabling Smooth\u00a0Payments™',
  'smooth-payments.start-receiving.section-title':
    'Start receiving rent\u00a0immediately. Enable\u00a0Smooth\u00a0Payments™!',
  'smooth-payments.free.subtitle': '100% Free • No\u00a0Fees\u00a0Whatsoever',
  'smooth-payments.details-text':
    'Periods of vacancy can be difficult financially, especially with a mortgage or HOA fees. With Belong Smooth Payments™, you keep receiving rent while we focus on finding someone to love your home, all at no\u00a0additional\u00a0cost.',
  'smooth-payments.starting-today.title': 'Starting today...',
  'smooth-payments.starting-today.text':
    'Receive Smooth Payments™ monthly until we find someone to love\u00a0your\u00a0home.',
  'smooth-payments.placed-residents.title': 'Once we place a\u00a0resident...',
  'smooth-payments.placed-residents.text':
    'We’ll deduct 1/12th of all Smooth\u00a0Payments™ you received each month over the course of 12 months, the length of our standard\u00a0lease.',
  'smooth-payments.why.title': 'Why?',
  'smooth-payments.why.text': 'Because a bird in the hand is worth two in the Belong\u00a0bush.',
  'smooth-payments.modal.title': 'Introducing Smooth\u00a0Payments™',
  'smooth-payments.modal.secondary-title': 'Only At Belong',
  'smooth-payments.standalone.success.title': "Smooth! You'll be receiving your first Smooth\u00a0Payment™ shortly.",
};
